import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuIcon from '../../../../assets/icons/menuBarIcons/MenuIcon';
import MyExperiencesIcon from '../../../../assets/icons/menuBarIcons/MyExperiencesIcon';
import NotificationsIcon from '../../../../assets/icons/menuBarIcons/NotificationsIcon';
import * as SC from './styles';
import ConfirmActionModal from '../Modals/ConfirmActionModal';
import MenuBarItem, { MenuBarItemProps } from './MenuBarItem';
import MindFeedIcon from '../../../../assets/icons/menuBarIcons/MindFeedIcon';
import ExmindsLogo from '../../../../assets/logo/ExmindsLogo';

interface MenuBarProps {
  isExminder?: boolean;
  notificationsCount?: number;
  isOnboardingSkipped: boolean;
  onMenuClick: CallbackFunction;
  onMindFeedClick: CallbackFunction;
  onNotificationClick: CallbackFunction;
  onMarketplaceClick: CallbackFunction;
  onStarClick: CallbackFunction;
  onBuildProfile: CallbackFunction;
}

export default function MenuBar({
  isExminder,
  notificationsCount = 0,
  isOnboardingSkipped,
  onMenuClick,
  onMindFeedClick,
  onNotificationClick,
  onMarketplaceClick,
  onStarClick,
  onBuildProfile,
}: MenuBarProps) {
  const MenuItemEnum = {
    MENU: 'menu',
    EXPERIENCES: 'experiences',
    UNIVERSE: 'universe',
    MIND_FEED: 'mindFeed',
    NOTIFICATIONS: 'notifications',
  };

  const { t } = useTranslation();
  const [isBuildProfileModalOpen, setIsBuildProfileModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsBuildProfileModalOpen(true);
  };

  const menuItems: MenuBarItemProps[] = [
    {
      name: 'mindFeed',
      value: MenuItemEnum.MIND_FEED,
      icon: <MindFeedIcon />,
      iconSize: { width: '36px', height: '27px' },
      text: t('menuBar.mindFeed'),
      disabled: false,
      clickFunction: onMindFeedClick,
      hasNotificationDot: false,
    },
    {
      name: 'notifications',
      value: MenuItemEnum.NOTIFICATIONS,
      icon: <NotificationsIcon />,
      iconSize: { width: '27px', height: '27px' },
      text: t('menuBar.notifications'),
      disabled: false,
      hasNotificationDot: true,
      clickFunction: isOnboardingSkipped
        ? handleOpenModal
        : onNotificationClick,
    },
    {
      name: 'universe',
      value: MenuItemEnum.UNIVERSE,
      icon: <ExmindsLogo />,
      iconSize: { width: '45px', height: '27px' },
      text: t('menuBar.universe'),
      disabled: false,
      hasNotificationDot: false,
      clickFunction: onMarketplaceClick,
    },
    {
      name: 'myExperiences',
      value: MenuItemEnum.EXPERIENCES,
      icon: <MyExperiencesIcon />,
      iconSize: { width: '40px', height: '27px' },
      text: t('menuBar.myExperiences'),
      disabled: false,
      hasNotificationDot: false,
      clickFunction: isOnboardingSkipped ? handleOpenModal : onStarClick,
    },
    {
      name: 'menu',
      value: MenuItemEnum.MENU,
      icon: <MenuIcon />,
      iconSize: { width: '30px', height: '27px' },
      text: t('menuBar.menu'),
      disabled: false,
      hasNotificationDot: false,
      clickFunction: onMenuClick,
    },
  ];

  return (
    <SC.Container>
      <SC.MainContent>
        <SC.ItemsContainer>
          {menuItems.map((item) => (
            <MenuBarItem
              key={item.value}
              item={item}
              notificationsCount={notificationsCount}
            />
          ))}
        </SC.ItemsContainer>
      </SC.MainContent>
      <ConfirmActionModal
        isOpen={isBuildProfileModalOpen}
        onClose={() => {
          setIsBuildProfileModalOpen(false);
        }}
        onConfirm={onBuildProfile}
        title={t(`createProfile.onBoardingSkippedModal.title`)}
        description={[t(`createProfile.onBoardingSkippedModal.subtitle`)]}
        cancelText={t(`general.cancel`)}
        confirmationText={t(
          `createProfile.onBoardingSkippedModal.createProfile`
        )}
      />
    </SC.Container>
  );
}
