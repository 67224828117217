import { Placement } from 'uiModels/enums/floatingUI';
import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import Tooltip from '../FloatingUI/Tooltip';
import VerifiedBadgeIcon from '../../../../assets/images/icons/VerifiedBadgeIcon.webp';
import { Size } from '../../../../styles/theme/types';

interface Props {
  placement?: Placement;
  size?: Size;
}

export default function VerifiedBadge({
  placement = Placement.TOP,
  size = { width: '34px', height: '34px' },
}: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip content={t('tooltip.verifiedBadge')} placement={placement}>
      <SC.VerifiedBadgeIconContainer icon={VerifiedBadgeIcon} size={size} />
    </Tooltip>
  );
}
