export enum ExperienceType {
  SHADOWING = 'shadowing',
  HANDS_ON = 'hands_on',
  MENTORSHIP = 'mentorship',
}

export enum ExperienceDateType {
  FIXED = 'fixed',
  VARIABLE = 'variable',
}

export enum PaymentType {
  REGULAR = 'regular',
  AUCTION = 'auction',
}

export enum Currency {
  EUR = 'EUR',
  RON = 'RON',
  USD = 'USD',
}

export enum ErrorCode {
  PhoneAlreadyExists = 1000,
  EmailAlreadyExists = 1001,
  PackageIsBeingPurchased = 2000,
  PackagePublishWithPurchaseDeadlineBelow2HoursFromNow = 2001,
  StripeNoCouponFound = 8998,
  StripeCouponNotValid = 8999,
  StripeSellerAccountNotFound = 9000,
  StripeSellerAccountNotEnabled = 9001,
  ContentCreatorPhoneNotVerified = 9002,
  BenefitsOrRequirementsNotSet = 9003,
}

export enum OpenedModalExminderProfile {
  Work = 'work',
  Education = 'education',
  Award = 'award',
  Milestone = 'milestone',
}

export enum MessageNotificationType {
  P2P = 'p2p',
  Group = 'group',
}

export enum SideBarSteps {
  MainPageStep = 0,
  ViewChangeConfirmationStep = 1,
  CompleteProfileStep = 2,
}

export enum ReportSteps {
  REASON = 0,
  DESCRIPTION = 1,
}

export enum SearchFilters {
  ACCOUNTS = 'accounts',
  EXPERIENCES = 'experiences',
  AUCTIONS = 'auctions',
}

export enum SvgPath {
  FILL = 'fill',
  STROKE = 'stroke',
}

export enum AppMode {
  INDIVIDUAL = 'Individual',
  BUSINESS = 'Business',
}
