import styled from 'styled-components';
import { Size } from '../../../../styles/theme/types';

export const VerifiedBadgeIconContainer = styled.div<{
  icon: string;
  size: Size;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size.width};
  height: ${({ size }) => size.height};
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-repeat: no-repeat;
`;
