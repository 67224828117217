import * as SC from './styles';
import { backgroundContainerClass } from '../../../../../helpers/constants/styling';

const LoadingLogoIcon = '/loadingLogo.webp';

export default function LoadingPageLayout() {
  return (
    <SC.Container>
      <SC.Content className={backgroundContainerClass}>
        <SC.IconContainer icon={LoadingLogoIcon} />
      </SC.Content>
    </SC.Container>
  );
}
