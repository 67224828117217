import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedMeetingPlacesPage = lazy(
  () =>
    import('../components/smart/pages/meetingPlaces/ConnectedMeetingPlacesPage')
);

const MEETING_PLACES: Route = {
  component: ConnectedMeetingPlacesPage,
  path: PATH.MEETING_PLACES,
  isProtected: true,
  hasMenuBar: true,
};

export default [MEETING_PLACES];
