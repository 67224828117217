import { JSX, SVGProps } from 'react';

export default function TimeClockIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1507 0.158203C9.07836 0.158203 7.05258 0.778271 5.32951 1.93999C3.60644 3.10172 2.26347 4.75292 1.47043 6.68479C0.677391 8.61667 0.469896 10.7425 0.874185 12.7933C1.27847 14.8442 2.27639 16.728 3.74174 18.2066C5.20709 19.6852 7.07406 20.6922 9.10655 21.1001C11.139 21.508 13.2458 21.2987 15.1604 20.4985C17.0749 19.6982 18.7113 18.3431 19.8627 16.6045C21.014 14.8659 21.6285 12.8218 21.6285 10.7307C21.6285 9.34232 21.3575 7.96751 20.8309 6.68479C20.3044 5.40208 19.5326 4.23657 18.5596 3.25482C17.5867 2.27307 16.4316 1.49431 15.1604 0.962988C13.8891 0.431669 12.5266 0.158203 11.1507 0.158203ZM11.1507 19.1887C9.49282 19.1887 7.8722 18.6927 6.49374 17.7633C5.11529 16.8339 4.04091 15.513 3.40648 13.9675C2.77205 12.422 2.60605 10.7213 2.92948 9.08065C3.25291 7.43995 4.05125 5.93288 5.22352 4.75C6.3958 3.56713 7.88938 2.76158 9.51538 2.43523C11.1414 2.10887 12.8268 2.27637 14.3584 2.91654C15.8901 3.5567 17.1992 4.64079 18.1203 6.0317C19.0413 7.42261 19.5329 9.05789 19.5329 10.7307C19.5329 12.9739 18.6498 15.1253 17.0778 16.7114C15.5058 18.2976 13.3738 19.1887 11.1507 19.1887ZM11.1507 4.38721C10.8728 4.38721 10.6063 4.4986 10.4098 4.69687C10.2133 4.89515 10.1029 5.16406 10.1029 5.44446V10.1175L7.90255 11.3968C7.7007 11.5122 7.54252 11.692 7.45282 11.9078C7.36312 12.1237 7.34696 12.3636 7.40689 12.5897C7.46682 12.8159 7.59944 13.0156 7.78395 13.1575C7.96847 13.2994 8.19444 13.3755 8.42644 13.3739C8.60999 13.3751 8.79065 13.3277 8.95033 13.2364L11.6746 11.6505L11.7689 11.5554L11.9365 11.4179C11.9775 11.3656 12.0126 11.3089 12.0413 11.2488C12.0754 11.192 12.1035 11.1318 12.1251 11.069C12.1536 11.0018 12.1713 10.9304 12.1775 10.8576L12.1985 10.7307V5.44446C12.1985 5.16406 12.0881 4.89515 11.8916 4.69687C11.6951 4.4986 11.4286 4.38721 11.1507 4.38721Z"
        fill="black"
      />
    </svg>
  );
}
