import { ReactElement } from 'react';
import * as SC from './styles';
import TabButton from '../TabButton';

interface Tab {
  icon: ReactElement;
  label: string;
  value: string;
  isChecked: boolean;
}

interface Props {
  tabs: Tab[];
  activeTab: string;
  onTabFilter: CallbackFunction;
}

export default function TabBar({ tabs, activeTab, onTabFilter }: Props) {
  const handleCheck = (item: string) => {
    onTabFilter(item);
  };

  const tabItems = tabs?.map((tab: Tab) => (
    <SC.DivContent key={tab.label}>
      <TabButton
        icon={tab.icon}
        label={tab.label}
        value={tab.value}
        isChecked={activeTab === tab.value}
        handleCheck={handleCheck}
      />
    </SC.DivContent>
  ));

  return (
    <SC.Container>
      <SC.MainContent>
        <SC.ButtonsContainer>{tabItems}</SC.ButtonsContainer>
      </SC.MainContent>
    </SC.Container>
  );
}
