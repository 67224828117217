import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getDomainOptions } from 'helpers/functions/getDomainOptions';
import * as SC from './styles';
import ExperienceTypeButton from './ExperienceTypeButton';
import {
  Currency,
  ExperienceType,
} from '../../../../../helpers/constants/enums';
import DropdownInput, { Option } from '../../../atomics/DropdownInput';
import Button from '../../../atomics/Button';
import classic from '../../../../../styles/theme/classic';

export type ExperienceUrlFilters = {
  experienceType: string;
  minPrice: string;
  maxPrice: string;
  currency: string;
  country: string;
  city: string;
  language: string;
  domain: string;
};
export interface Filters {
  experienceType: string;
  minPrice: string;
  maxPrice: string;
  currency: Option;
  country: Option;
  city: Option;
  language: Option;
  domain: Option;
}

interface FiltersFormProps {
  filters: ExperienceUrlFilters;
  requestIsLoading: boolean;
  countries: Option[];
  cities: Option[];
  languages: Option[];
  handleSelectedCountry: CallbackFunction;
  handleSearchedCity: CallbackFunction;
  onApplyFilters: (data: Filters) => void;
  onClose: CallbackFunction;
  onClearFilters: (data: Filters) => void;
}

export default function FiltersForm({
  filters,
  requestIsLoading,
  countries,
  cities,
  languages,
  handleSelectedCountry,
  handleSearchedCity,
  onApplyFilters,
  onClose,
  onClearFilters,
}: FiltersFormProps) {
  const { t } = useTranslation();

  const [searchedCity, setSearchedCity] = useState('');

  const domains = getDomainOptions();

  const currencies = [
    {
      label: 'RON',
      value: Currency.RON,
    },
  ];

  const validatePriceRange = (
    value: number | undefined | null,
    context: Yup.TestContext
  ): boolean => {
    const { minPrice, maxPrice } = context.parent as {
      minPrice: number | undefined | null;
      maxPrice: number | undefined | null;
    };

    if (
      context.path === 'minPrice' &&
      value !== undefined &&
      value !== null &&
      maxPrice !== undefined &&
      maxPrice !== null
    ) {
      return maxPrice >= value;
    }
    if (
      context.path === 'maxPrice' &&
      value !== undefined &&
      value !== null &&
      minPrice !== undefined &&
      minPrice !== null
    ) {
      return minPrice <= value;
    }
    return true;
  };

  const resetValues = {
    experienceType: '',
    minPrice: '',
    maxPrice: '',
    currency: { value: '', label: '' },
    country: { value: '', label: '' },
    city: { value: '', label: '' },
    language: { value: '', label: '' },
    domain: { value: '', label: '' },
  };

  const validationSchema = Yup.object().shape({
    experienceType: Yup.string(),
    minPrice: Yup.number()
      .nullable()
      .typeError(t(`errors.notANumber`))
      .min(0, `${t(`errors.specific.minimumValue`)} 0`)
      .max(999999, `${t(`errors.specific.maximumValue`)} 999999`)
      .test(
        'isValidMinimumPrice',
        t(`errors.price.minimumPriceExceedsMax`),
        validatePriceRange
      ),
    maxPrice: Yup.number()
      .nullable()
      .typeError(t(`errors.notANumber`))
      .min(0, `${t(`errors.specific.minimumValue`)} 0`)
      .max(999999, `${t(`errors.specific.maximumValue`)} 999999`)
      .test(
        'isValidMaximumPrice',
        t(`errors.price.maximumPriceBelowMin`),
        validatePriceRange
      ),
    currency: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
    country: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
    city: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
    language: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
    domain: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  });
  function findLabel(itemValue: string, options: Option[]) {
    const res = options.find((x) => x.value === itemValue)?.label;
    return res ?? '';
  }

  const defaultInitialValues = {
    experienceType: filters.experienceType,
    minPrice: filters.minPrice,
    maxPrice: filters.maxPrice,
    currency: {
      value: filters.currency,
      label: findLabel(filters.currency, currencies) ?? '',
    },
    country: {
      value: filters.country,
      label: findLabel(filters.country, countries) ?? '',
    },
    city: {
      value: filters.city,
      label: findLabel(filters.city, cities) ?? '',
    },
    language: {
      value: filters.language,
      label: findLabel(filters.language, languages) ?? '',
    },
    domain: {
      value: filters.domain,
      label: findLabel(filters.domain, domains) ?? '',
    },
  };

  const initialValues = defaultInitialValues || resetValues;

  return (
    <SC.Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const filtersData: Filters = {
            experienceType: values.experienceType,
            minPrice: values.minPrice,
            maxPrice: values.maxPrice,
            currency: values.currency,
            country: values.country,
            city: values.city,
            language: values.language,
            domain: values.domain,
          };
          onApplyFilters(filtersData);
          onClose();
        }}
      >
        {({ errors, touched, values, setFieldValue, resetForm }) => (
          <SC.FormWrapper>
            <SC.InputsContainer>
              <SC.Section>
                <SC.BoldText>{t(`filters.experienceType`)}</SC.BoldText>
                <SC.ExperienceTypeButtonsContainer>
                  <ExperienceTypeButton
                    experienceType={ExperienceType.HANDS_ON}
                    isActive={values.experienceType === ExperienceType.HANDS_ON}
                    onClick={() =>
                      setFieldValue(
                        'experienceType',
                        values.experienceType === ExperienceType.HANDS_ON
                          ? ''
                          : ExperienceType.HANDS_ON
                      )
                    }
                  />
                  <ExperienceTypeButton
                    experienceType={ExperienceType.MENTORSHIP}
                    isActive={
                      values.experienceType === ExperienceType.MENTORSHIP
                    }
                    onClick={() =>
                      setFieldValue(
                        'experienceType',
                        values.experienceType === ExperienceType.MENTORSHIP
                          ? ''
                          : ExperienceType.MENTORSHIP
                      )
                    }
                  />
                  <ExperienceTypeButton
                    experienceType={ExperienceType.SHADOWING}
                    isActive={
                      values.experienceType === ExperienceType.SHADOWING
                    }
                    onClick={() =>
                      setFieldValue(
                        'experienceType',
                        values.experienceType === ExperienceType.SHADOWING
                          ? ''
                          : ExperienceType.SHADOWING
                      )
                    }
                  />
                </SC.ExperienceTypeButtonsContainer>
              </SC.Section>
              <SC.Section>
                <SC.BoldText>{t(`filters.priceRange`)}</SC.BoldText>
                <SC.PriceRangeInputContainer>
                  <SC.PriceRangeInput>
                    <SC.Input
                      name="minPrice"
                      type="text"
                      error={
                        errors.minPrice && touched.minPrice ? 'true' : undefined
                      }
                      placeholder={t(`filters.minimumPrice`)}
                    />
                    {errors.minPrice && touched.minPrice ? (
                      <SC.Error>{errors.minPrice}</SC.Error>
                    ) : null}
                  </SC.PriceRangeInput>
                  <SC.PriceRangeDivider>-</SC.PriceRangeDivider>
                  <SC.PriceRangeInput>
                    <SC.Input
                      name="maxPrice"
                      type="text"
                      error={
                        errors.maxPrice && touched.maxPrice ? 'true' : undefined
                      }
                      placeholder={t(`filters.maximumPrice`)}
                    />
                    {errors.maxPrice && touched.maxPrice ? (
                      <SC.Error>{errors.maxPrice}</SC.Error>
                    ) : null}
                  </SC.PriceRangeInput>
                </SC.PriceRangeInputContainer>
              </SC.Section>
              <SC.Section>
                <SC.BoldText>{t(`filters.currency`)}</SC.BoldText>
                <SC.CurrencyDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    optionSelected={{
                      label: values.currency.label,
                      value: values.currency.value,
                    }}
                    onSelectOption={(option) => {
                      setFieldValue('currency', option);
                    }}
                    options={currencies}
                  />
                </SC.CurrencyDropdownInputContainer>
              </SC.Section>
              <SC.Section>
                <SC.BoldText>{t(`filters.country`)}</SC.BoldText>
                <SC.CountryDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    optionSelected={{
                      label: values.country.label,
                      value: values.country.value,
                    }}
                    onSelectOption={(option) => {
                      handleSelectedCountry(option);
                      setFieldValue('country', option);
                      setFieldValue('city', {
                        label: '',
                        value: '',
                      });
                    }}
                    options={countries}
                    error={Boolean(errors.country && touched.country)}
                  />
                  {errors.country?.value && touched.country ? (
                    <SC.Error>{errors.country.value}</SC.Error>
                  ) : null}
                </SC.CountryDropdownInputContainer>
              </SC.Section>
              <SC.Section>
                <SC.BoldText>{t(`filters.city`)}</SC.BoldText>
                <SC.CityDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    disabled={!values.country.value}
                    optionSelected={{
                      label: values.city.label,
                      value: values.city.value,
                    }}
                    onInputChange={(value: string) => {
                      handleSearchedCity(value);
                      setSearchedCity(value);
                    }}
                    onSelectOption={(option) => {
                      setSearchedCity('');
                      setFieldValue('city', option);
                    }}
                    options={cities}
                    serverSideSearchValue={searchedCity}
                    isServerSideFiltering
                    error={Boolean(errors.city && touched.city)}
                  />
                </SC.CityDropdownInputContainer>
              </SC.Section>
              <SC.Section>
                <SC.BoldText>{t(`filters.spokenLanguage`)}</SC.BoldText>
                <SC.LanguageDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    optionSelected={{
                      label: values.language.label,
                      value: values.language.value,
                    }}
                    onSelectOption={(option) => {
                      setFieldValue('language', option);
                    }}
                    options={languages}
                  />
                </SC.LanguageDropdownInputContainer>
              </SC.Section>
              <SC.Section>
                <SC.BoldText>{t(`filters.domain`)}</SC.BoldText>
                <SC.DomainDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    optionSelected={{
                      label: values.domain.label,
                      value: values.domain.value,
                    }}
                    onSelectOption={(option) => {
                      setFieldValue('domain', option);
                    }}
                    options={domains}
                  />
                </SC.DomainDropdownInputContainer>
              </SC.Section>
            </SC.InputsContainer>
            <SC.ButtonsWrapper>
              <Button
                type="button"
                color={classic.palette.button.main.marine}
                onClick={() => {
                  onClearFilters(initialValues);
                  resetForm({ values: resetValues });
                }}
              >
                {t(`general.clear`)}
              </Button>
              <Button
                type="submit"
                color={classic.palette.button.main.yellow}
                isLoading={requestIsLoading}
              >
                {t(`general.apply`)}
              </Button>
            </SC.ButtonsWrapper>
          </SC.FormWrapper>
        )}
      </Formik>
    </SC.Container>
  );
}
