import { ReactElement } from 'react';
import * as SC from './styles';
import { Size } from '../../../../../styles/theme/types';

export interface MenuBarItemProps {
  name: string;
  value: string;
  icon: ReactElement;
  iconSize: Size;
  text: string;
  disabled: boolean;
  hasNotificationDot: boolean;
  clickFunction: CallbackFunction;
}

interface Props {
  item: MenuBarItemProps;
  notificationsCount: number;
}

export default function MenuBarItem({ item, notificationsCount }: Props) {
  return (
    <SC.ItemContainer
      onClick={() => {
        item.clickFunction();
      }}
      disabled={item.disabled}
    >
      <SC.IconWrapper>
        <SC.IconContainer $iconSize={item.iconSize}>
          {item.icon}
        </SC.IconContainer>
        {item.hasNotificationDot && notificationsCount > 0 && (
          <SC.NotificationDot>
            {notificationsCount <= 9 ? notificationsCount : '9+'}
          </SC.NotificationDot>
        )}
      </SC.IconWrapper>
      <SC.ItemText>{item.text}</SC.ItemText>
    </SC.ItemContainer>
  );
}
