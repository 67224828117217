import styled from 'styled-components';
import { zIndex } from '../../../../../helpers/constants/zIndexValues';

export const Container = styled.div``;

export const ReferenceElement = styled.div``;

export const TooltipContent = styled.div<{
  fill: string;
  color: string;
  width: string;
  minWidth?: string;
}>`
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: calc(100% - 40px);

  background: ${({ fill }) => fill};
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 10px;
  z-index: ${zIndex.tooltip};

  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ color }) => color};
  text-align: center;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;
