import { RefObject, useEffect } from 'react';
import Hls, { Fragment } from 'hls.js';

interface HlsProps {
  videoElement: RefObject<HTMLVideoElement>;
  videoSrc: string;
  autoPlay?: boolean;
  hasMasterPlaylist?: boolean;
}

const useHLS = ({
  videoElement,
  videoSrc,
  autoPlay,
  hasMasterPlaylist,
}: HlsProps) => {
  useEffect(() => {
    if (videoElement.current === null || !hasMasterPlaylist) {
      return () => {};
    }
    if (Hls.isSupported()) {
      const hls = new Hls({ startLevel: -1 }); // auto video quality

      hls.loadSource(videoSrc);
      hls.attachMedia(videoElement.current);

      let previousFragLevel: number | null = null;

      const handleFragChanged = (event: string, data: { frag: Fragment }) => {
        const currentFragLevel = data.frag.level;

        if (
          previousFragLevel !== null &&
          currentFragLevel > previousFragLevel
        ) {
          const currentTime = videoElement.current?.currentTime || 0;

          // Trigger buffer flushing for lower-quality segments
          hls.trigger(Hls.Events.BUFFER_FLUSHING, {
            startOffset: 0,
            endOffset: currentTime - 0.1, // Flush up to the current playback position
            type: 'video',
          });
        }

        previousFragLevel = currentFragLevel;
      };

      hls.on(Hls.Events.FRAG_CHANGED, handleFragChanged);

      // Handle auto-play if specified
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          videoElement.current?.play();
        }
      });

      return () => {
        hls.off(Hls.Events.FRAG_CHANGED, handleFragChanged);
        hls.destroy();
      };
    }

    if (videoElement.current.canPlayType('application/vnd.apple.mpegurl')) {
      const video = videoElement.current;
      video.src = videoSrc;
    }

    return () => {};
  }, [videoElement, videoSrc, autoPlay]);
};

export default useHLS;
