export const experienceId = ':experienceId';

export const userId = ':userId';

export const channelId = ':channelId';

export const experienceStatus = 'experienceStatus';

export const contentCreatorSlug = ':contentCreatorSlug';

export const experienceSlug = ':experienceSlug';

export const contestId = ':contestId';
