import { useTranslation } from 'react-i18next';
import { ExperienceCardType } from 'uiModels/enums';
import { isSameMonth, isSameYear } from 'date-fns';
import * as SC from './styles';
import GiftIcon from '../../../../../assets/icons/buttonIcons/GiftIcon';
import CalendarIcon from '../../../../../assets/icons/CalendarIcon';
import DateTimeUtils from '../../../../../helpers/classes/DateTimeUtils';

interface Props {
  cardType: ExperienceCardType;
  startDate: Date;
  endDate: Date;
}

export default function ExperienceCardTypeInfo({
  cardType,
  startDate,
  endDate,
}: Props) {
  const { t } = useTranslation();

  const formattedDateRange = (): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (DateTimeUtils.isSameDay(start, end)) {
      return DateTimeUtils.formatDate(start);
    }
    if (isSameYear(start, end)) {
      if (isSameMonth(start, end)) {
        return `${DateTimeUtils.formatDate(start, 'dd')} - ${DateTimeUtils.formatDate(
          end
        )}`;
      }
      return `${DateTimeUtils.formatDate(start, 'dd MMM')} - ${DateTimeUtils.formatDate(
        end
      )}`;
    }
    if (isSameMonth(start, end)) {
      return `${DateTimeUtils.formatDate(start)} - ${DateTimeUtils.formatDate(end)}`;
    }
    return `${DateTimeUtils.formatDate(start)} - ${DateTimeUtils.formatDate(end)}`;
  };

  return (
    <SC.Container>
      {cardType === ExperienceCardType.Contest ? (
        <SC.ContestContainer>
          <SC.IconContainer>
            <GiftIcon />
          </SC.IconContainer>
          <SC.ContestText>{t('experience.contest.card')}</SC.ContestText>
        </SC.ContestContainer>
      ) : (
        <SC.DateContainer>
          <SC.IconContainer>
            <CalendarIcon />
          </SC.IconContainer>
          <SC.DateText>
            {cardType === ExperienceCardType.Permanent
              ? t('experience.permanent.name')
              : `${formattedDateRange()}${
                  DateTimeUtils.isSameDay(startDate, endDate)
                    ? ` - ${DateTimeUtils.extractTimeFromDate(startDate)}`
                    : ``
                }`}
          </SC.DateText>
        </SC.DateContainer>
      )}
    </SC.Container>
  );
}
