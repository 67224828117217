import React from 'react';
import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import { SideBarSteps } from '../../../../../../helpers/constants/enums';
import Thunder3DIcon from '../../../../../../assets/images/icons/Thunder3DIcon.webp';

interface Props {
  currentStep: number;
}

export default function ChangeViewConfirmationStep({ currentStep }: Props) {
  const { t } = useTranslation();
  if (currentStep !== SideBarSteps.ViewChangeConfirmationStep) {
    return null;
  }

  return (
    <SC.Container>
      <SC.IconContainer icon={Thunder3DIcon} />
      <SC.BoldText>{t(`sidebarMenu.changeRole.areYouSure`)}</SC.BoldText>
    </SC.Container>
  );
}
