import styled from 'styled-components';
import { Size } from '../../../../../styles/theme/types';

export const ItemContainer = styled.div<{
  disabled?: boolean;
}>`
  width: 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media only screen and (max-width: 500px) {
    margin: auto;
  }

  &:first-child {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 10px;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const IconWrapper = styled.div`
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const IconContainer = styled.div<{
  $iconSize: Size;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${({ $iconSize }) => $iconSize.width};
    height: ${({ $iconSize }) => $iconSize.height};
  }

  @media (hover: hover) and (pointer: fine) {
    ${ItemContainer}:hover:not([disabled]) & {
      & svg {
        path {
          fill: ${({ theme }) => theme.palette.main.turquoise};
        }
      }
    }
  }
`;

export const ItemText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(-7)};
  line-height: ${({ theme }) => theme.typography.pxToRem(-3)};
  color: ${({ theme }) => theme.palette.main.marine};

  @media (hover: hover) and (pointer: fine) {
    ${ItemContainer}:hover:not([disabled]) & {
      color: ${({ theme }) => theme.palette.main.turquoise};
    }
  }
`;

export const NotificationDot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(-6)};
  line-height: ${({ theme }) => theme.typography.pxToRem(-6)};
  color: ${({ theme }) => theme.palette.main.white};

  position: absolute;
  width: 16px;
  height: 16px;
  padding-left: 1px;
  right: -6px;
  top: -2px;
  content: '';
  background: #de0f0f;
  border-radius: 50%;
`;
