import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedContestRules = lazy(
  () =>
    import('../components/smart/pages/experienceContest/ConnectedContestRules')
);

const ConnectedContestFinalStep = lazy(
  () =>
    import(
      '../components/smart/pages/experienceContest/ConnectedContestFinalStep'
    )
);

const CONTEST_RULES: Route = {
  component: ConnectedContestRules,
  path: PATH.CONTEST.RULES,
  isProtected: true,
};

const CONTEST_FINAL_STEP: Route = {
  component: ConnectedContestFinalStep,
  path: PATH.CONTEST.FINAL_STEP,
  isProtected: true,
};

export default [CONTEST_RULES, CONTEST_FINAL_STEP];
